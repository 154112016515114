<template>
  <div id="lawyers" style="padding-top: 126px">
    <app-header />
    <div class="current_position w">
      <div class="f838 size_14">当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <!-- <el-breadcrumb-item class="d8f">政策法规</el-breadcrumb-item> -->
        <el-breadcrumb-item class="" style="color: #ff6618"
          >服务专员</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="current_position w">
      <div style="background-color: #fafafa">
        <div class="max_width min_width">
          <section class="bgfff">
            <div
              style="
                width: 1518px;
                height: 185px;
                padding: 30px;
                background-color: rgb(255, 221, 204);
                border-radius: 5px;
              "
            >
              <div>
                <div class="flex align_start" style="padding: 10px 0px">
                  <div class="fnf size_15 weight marr_30 text_nowrap">
                    领域筛选：
                  </div>
                  <div class="text_line1" style="width: calc(100% - 75px)">
                    <span
                      class="fnf weight size_15 marr_30 laws_font"
                      :class="{ laws_tag: patterns == '' }"
                      @click="setTag('patterns')"
                      >全部</span
                    >
                    <span
                      class="fnf size_15 marr_30 laws_font"
                      v-for="(item, index) in options"
                      :key="index"
                      :class="{ laws_tag: patterns == item.id }"
                      @click="setTag('patterns', item.id)"
                      >{{ item.name }}</span
                    >
                  </div>
                </div>
              </div>
              <!-- 搜索框 -->
              <div class="flex align_center mart_20" style="margin-top: 20px">
                <div class="fnf size_15 weight text_nowrap">关键词搜索：</div>
                <div class="width_50 sear_search">
                  <el-input placeholder="请输入关键词" v-model="keyword" @keyup.enter.native="search()">
                    <i slot="suffix" class="el-icon-search" @click="search"></i>
                  </el-input>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <!-- 服务机构 -->
    <div style="margin-bottom: 20px" class="institution_list w">
      <div class="img">
        <img src="../../assets/image/组织.png" alt="" />
      </div>
      服务专员
    </div>
    <!-- 内容 -->
    <div class="Personal w">
      <div
        class="left"
        @click="gocommissionerdetails(item)"
        v-for="item in TableData"
        :key="item.id"
      >
        <div class="img">
          <img class="pic" :src="item.img" alt="" />
        </div>
        <div class="introduce">
          <div class="th">{{ item.name }}</div>
          <div class="tb">工作领城:{{ item.cate_name }}</div>
          <div class="tb tb2">
            工作单位
            <div>{{ item.unit }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="paginations.page_index"
        :page-sizes="paginations.page_sizes"
        :page-size="paginations.page_size"
        :layout="paginations.layout"
        :total="paginations.total"
      >
      </el-pagination>
    </div>
    <app-footer />
  </div>
</template>

<script>
import Header from "../../components/Header/header.vue";
import Footer from "../../components/Footer/footer.vue";
export default {
  data() {
    return {
      keyword: "",
      patterns: "", //领域塞选
      options: [],
      TableData: [],
      total: "",
      allTableData: [],
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
    };
  },
  created() {
    this.getcatelist();
    this.postcommissioner();
  },
  methods: {
    // 搜索
    search() {
      this.postcommissioner();
    },
    gocommissionerdetails(item) {
      console.log(item.ID, "789456");
      this.$router.push({
        path: "/commissionerdetails",
        query: { cid: item.ID },
      });
    },
    //专员列表
    postcommissioner() {
      let data = {
        token: localStorage.eleToken,
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
        keyword: this.keyword,
        pro_cate_id: this.patterns,
      };
      this.$post("select_commissioner_lst", data).then((res) => {
        this.allTableData = res.result;
        this.total = res.count;
        console.log(res, "this.r789es");
        this.setPaginations();
      });
    },

    setTag(name, id = "") {
      this[name] = id;
      this.postcommissioner();
    },
    // 擅长领域
    getcatelist() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("cate_list", data).then((res) => {
        this.options = res.result;
        console.log(this.options, "this.options");
      });
    },

    handleClick(tab, event) {
      this.sta = Number(tab.index) + 1;
      this.getorderlist();
      this.getcatelist();
    },
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;
      this.TableData = this.allTableData.filter((item, index) => {
        return index < page_size;
      });
    },

    // 分页

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allTableData[i]) {
          tables.push(this.allTableData[i]);
        }
        this.TableData = tables;
      }
    },

    //重置分页
    setPaginations() {
      // 总页数
      this.paginations.total = this.total;
      this.paginations.page_index = 1;
      this.paginations.page_size = 10;
      //设置默认的分页数据
      if (this.allTableData.length != 0) {
        this.TableData = this.allTableData.filter((item, index) => {
          return index < this.paginations.page_size;
        });
      } else {
        this.TableData = [];
      }
    },
  },
  components: { "app-header": Header, "app-footer": Footer },
};
</script>

<style scoped>
.introduce {
  width: 147px;
  word-break: break-all;
}
#lawyers .block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 130px;
  margin-top: 50px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* 	overflow: hidden;
     */
}
.w {
  width: 1520px;
  margin: 0 auto;
}
.current_position {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.current_position_text {
  font-size: 14px;
  color: #838383;
}
.d8f {
  color: #0d8eff;
}
.institution_list {
  display: flex;
  align-items: center;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.institution_list .img {
  width: 18px;
  height: 18px;
  /* border: 1px dashed #666; */
  margin-right: 20px;
}
.institution_list span img {
  width: 100%;
  height: 100%;
}
.Personal {
  display: grid;
  gap: 0px 37px;
  grid-template-columns: repeat(4, 1fr);
}
.Personal .left {
  display: flex;
  width: 355px;
  height: 220px;
  border: 1px solid #e8e8e8;
  padding: 20px;
  margin-bottom: 30px;
}
.Personal .left .img {
  margin-right: 20px;
  width: 150px;
  height: 180px;
  object-fit: cover;
  overflow: hidden;
}
.Personal .left .img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.introduce .th {
  height: 33.33%;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #333333;
}
.introduce .tb {
  height: 33.33%;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.introduce .tb2 {
  color: #595959;
}
.pic {
  transition: all 1s;
}
.pic:hover {
  transform: scale(1.5);
  /* transition: all 1s; */
}

.laws_tag {
  padding: 3px 12px;

  color: #ff7129;
  background: #ffffff;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
}
.marr_30 {
  margin-right: 30px;
}
.sear_search .el-icon-search {
  color: #ff7129 !important;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  top: 8px;
  position: relative;
}
</style>
